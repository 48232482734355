var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-3 rounded-t-0",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.sn",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.report_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.report_type_display)+" ")]}},{key:"item.filters",fn:function(ref){
var item = ref.item;
return [(item.filters.length)?_c('div',_vm._l((item.filters),function(filter,index){return _c('div',{key:index + filter.title},[_vm._v(" "+_vm._s(filter.title)+": "+_vm._s(filter.text)+" ")])}),0):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.created))+" ")]}},{key:"item.report_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":item.report_status_display.text_color,"color":item.report_status_display.bg_color,"small":""}},[_vm._v(" "+_vm._s(item.report_status_display.text)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},_vm._l((_vm.actions(item)),function(action){return _c('v-btn',{key:action.key,staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.actionHandler(action.key, item)}}},[(action.icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(action.icon))]):_vm._e(),_vm._v(" "+_vm._s(action.text)+" ")],1)}),1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.showDownloadLoading),callback:function ($$v) {_vm.showDownloadLoading=$$v},expression:"showDownloadLoading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"pt-3"},[_vm._v(" Please wait, downloading report... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }